import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import ContentContainer, { IContentContainerProps } from './components/ContentContainer';
import Sidebar from './components/Sidebar';
import { urls } from './Urls';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth, API } from 'aws-amplify';

Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_AMPLIFY_LOG_LEVEL;
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  API: {
    endpoints: [
      {
        name: "s3uAPI",
        endpoint: process.env.REACT_APP_BACKEND_HOST,
        custom_header: async () => {
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      }
    ]
  }
});

export const menuItemId = {
  Configurations: 0,
  Deployments: 1,
  Groups: 2,
};
export type TMenuItemId = $Values<typeof menuItemId>;

interface IAppState {
  activeMenuItem: TMenuItemId;
  authState?: string;
}

export class App extends React.Component<{}, IAppState> {
  mainContent: Object;

  handleSidebarItemClick = (active: TMenuItemId) => {
    this.contentSwitch(active);
    this.setState({ activeMenuItem: active });
  };

  constructor(props: {}) {
    super(props);
    this.mainContent = '';
    this.state = {
      activeMenuItem: menuItemId.Configurations,
    };
  }

  componentDidMount() {
    if (!this.state.authState) {
      Auth.currentAuthenticatedUser().then(user => {
        console.log(user);
        this.setState({ authState: 'signedIn' });
        this.handleSidebarItemClick(menuItemId.Configurations);
      }).catch(e => {
        console.log(e);
        this.setState({ authState: 'signIn' });
      });
    } else {
      this.handleSidebarItemClick(menuItemId.Configurations);
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">
            <strong>S3U</strong> Severely Simple Scaling Unit
          </h1>
        </header>
        <div className="container-fluid App-container my-3">
          <div className="row h-100">
            <Sidebar
              activeMenuItem={this.state.activeMenuItem}
              menuItemClickHandler={this.handleSidebarItemClick}
            />
            <Switch>
              <Redirect
                from="/"
                exact={true}
                strict={true}
                to="/list/configurations"
              />
              <Route
                path="/"
                exact={false}
                render={this.renderContentContainer.bind(this, {
                  content: this.mainContent[
                    Object.keys(menuItemId)[this.state.activeMenuItem].toLowerCase()
                  ],
                  contentType: Object.keys(menuItemId)[this.state.activeMenuItem]
                })}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  renderContentContainer = (
    props: IContentContainerProps
  ): React.Element<typeof ContentContainer> => {
    return <ContentContainer {...props} />;
  };

  contentSwitch(switchTo: TMenuItemId) {
    let urlPath: string;
    switch (switchTo) {
      case menuItemId.Configurations:
        urlPath = urls['configList'];
        break;

      case menuItemId.Deployments:
        urlPath = urls['deploymentList'];
        break;

      case menuItemId.Groups:
        urlPath = urls['groupList'];
        break;

      default:
        urlPath = urls['configList'];
        break;
    }

    const initReqParams = {
      response: true,
    }

    API.get("s3uAPI", urlPath, initReqParams)
      .then(response => {
        console.log("data")
        console.log(response.data)
        this.mainContent = response.data;
        this.setState({ ...this.state })
      })
      .catch(error => {
        console.log(error.response)
      })
  }
}

const greeterTheme = {
  navBar: { background: "#222", color: "white", border: "0px" },
}

export default withAuthenticator(App, true, [], null, greeterTheme);
