import React from 'react';
import './ContentContainer.css'

export interface IContentContainerProps {
  content: Object;
  contentType: string;
}

function ContentContainer(props: IContentContainerProps) {
  if (!props.content || !props.content.length) return (
    <div id="content-container" className="content-container">

      <strong>No active {props.contentType}</strong>
    </div>
  );
  return (
    <div id="content-container" className="content-container">
      <table>
        <thead>
          <tr>
            {Object.keys(props.content[0]).map((key: string) => {
              return (
                <th key={key}>{key}</th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {props.content.map((row: { [string]: string }[], i: Number) => {
            return (
              <tr key={i.toString()}>
                {Object.values(row).map((col: string, j: Number) => {
                  return (
                    <td key={j.toString()}>
                      {col}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ContentContainer;
