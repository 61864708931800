import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { menuItemId, type TMenuItemId } from '../App';
import './Sidebar.css';

interface ISidebarProps {
  activeMenuItem: TMenuItemId;
  menuItemClickHandler: (activeMenuitem: TMenuItemId) => void;
}

function Sidebar(props: ISidebarProps) {
  return (
    <nav className="col-sm-3 col-md-3 col-xl-2">
      <ul className="list-group nav nav-pills">
        {Object.keys(menuItemId).map(val => {
          const key = menuItemId[val];
          return (
            <li
              key={val}
              className="nav-item m-1"
              onClick={() => props.menuItemClickHandler(key)}
            >
              <NavLink
                key={val}
                to={`/list/${val.toLowerCase()}`}
                activeClassName="active bg-info"
                className={'nav-link text-body border' + (
                  key !== props.activeMenuItem ? ' bg-white' : ''
                )}
              >
                {val}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Sidebar;
