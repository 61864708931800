export const urls = {
  configApply: '/v1/config',
  configDelete: '/v1/config/<config_id>',
  configGet: '/v1/config/<config_id>',
  configList: '/v1/config',
  deploymentDelete: '/v1/deployment/<deployment_id>',
  deploymentInfo: '/v1/deployment/<deployment_id>',
  deploymentList: '/v1/deployment',
  groupInfo: '/v1/group/<group_id>',
  groupList: '/v1/group',
  groupSize: '/v1/group/<group_id>/size'
};
